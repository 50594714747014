import React from "react"

import "../App.css";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MonthlyEventCalendar from "./MonthlyEventCalendar";
import Panchang from "./Panchang";
import DrivingDirections from "./Direction";
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup'
import Button from 'react-bootstrap/Button'
import ImageGallery from 'react-photo-gallery';
import PoojaItemList from "./PoojaItemList";

import { Link } from "react-router-dom";

import { KumbhabhishekamPhotos, MahaRudramPhotos} from './photoGallery';
import PriestServiceRegistration from './PriestServiceRegistration'



 export default class Home extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "welcome",
            activePhotoGallery: "one",
            showPriestServiceRegistration: false ,
            showPoojaItemList: false ,
            poojaItemIndex: 1     
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handlePriestServiceRegistration = this.handlePriestServiceRegistration.bind(this);
        this.handlePoojaItemList = this.handlePoojaItemList.bind(this);
        this.handlePLState = this.handlePLState.bind(this);
        this.handlePSRState = this.handlePSRState.bind(this);
    }
    handleSelect(selectedTab) {
        // The active tab must be set into the state so that
        // the Tabs component knows about the change and re-renders.
        this.setState({
          activeTab: selectedTab
        });
    }

    handlePLState() {
      this.setState({showPoojaItemList: false});
    }

    handlePSRState() {
      this.setState({showPriestServiceRegistration: false});
    }

    handlePriestServiceRegistration(){
      return(
        <PriestServiceRegistration show={true} handlePSRState = {this.handlePSRState}/>
      )
    }

    handlePoojaItemList(){
      return(
        <PoojaItemList poojaIndex={this.state.poojaItemIndex} show={true} handlePLState = {this.handlePLState} />
      )
    }

    DonateTab() {
      return(
        <div>
        <p class="round-corners-10px" style={{display: "inline-block",width:"100%",textAlign: "left",justifyContent: 'flex-end',fontSize: "0.9rem"}}>
               <p>We, at Sri Raja Rajeswari Temple, offer our Prayers to Ambal that you and your families keep safe and stay healthy.
               </p>
               <p> Functioning  of the temple relies on its devotees. Please donate generously for temple activities. </p>
               <p>
                   Donation can be made to srrtsanjose@gmail.com through Paypal , Venmo. Or through Credit Card
               </p>
          
          <ul>
            <li> <a href="https://paybee.io/quickpay.html?handle=srrt#campaignList">Credit/Debit Card</a></li>
            <li> Paypal : srrtsanjose@gmail.com</li>
            <li> Venmo: srrtsanjose@gmail.com</li>
          </ul>
          </p>
        </div>
      );
    }

    welcomeTab(){
        return(
        <div>
        <h3 style={{textAlign: "center"}}> Welcome</h3>
        <div>
         
        <CardGroup className="round-corners-10px">
       
          <Card border="primary" className="regularEvents">
            <Card.Header style={{textAlign: "center", fontWeight: "bold"}}>Regular Events</Card.Header>
            <Card.Body>
              <Card.Text>
                 <Table size="sm">
                   <thead>
                     <tr>
                      <th style={{width :"50%"}}></th>
                      <th>Weekday</th>
                      <th>Dates(2024)</th>
                      <th>Time</th>
                    </tr>
                   </thead>
                   <tbody>
                    <tr>
                     <td>Sri Raja Rajeswari Ambal Abishekam</td>
                     <td>Sunday</td>
                     <td>Aug 4,11,18,25</td>
                     <td>5:30 pm</td>
                    </tr>
                    <tr>
                     <td>Sri Lalitha Sahasranama Archana</td>
                     <td>Tuesday & Friday </td>
                     <td>Aug 2,6,9,13,16,20,23,27,30</td>
                     <td>7:00 pm</td>
                    </tr>
                    <tr>
                     <td>Sankatahara Chaturthi</td>
                     <td>Thursday</td>
                     <td>Aug 22</td>
                     <td>7:00 pm</td>
                    </tr>
                    <tr>
                     <td>Prodosham </td>
                     <td>Thr,Fri</td>
                     <td>Aug 1,30</td>
                     <td>5:30 pm</td>
                    </tr>
                    <tr>
                     <td>Shukla Shashti</td>
                     <td>Saturday</td>
                     <td>Aug 10</td>
                     <td>5:30 pm</td>
                    </tr>  
                    <tr>
                     <td>Ayyappa Swamy Abishekam and Bhajan</td>
                     <td>Saturday</td>
                     <td>Aug 24</td>
                     <td>5:30 pm</td>
                    </tr>
                  
                   </tbody>
                  </Table>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card border="primary" className="regularEvents">
            <Card.Header style={{textAlign: "center", fontWeight: "bold"}}>Monthly Events</Card.Header>
            <Card.Body>
              <Card.Text>
                 <Table size="sm">
                   <thead>
                     <tr>
                      <th style={{width :"30%"}}></th>
                      <th>Weekday</th>
                      <th>Dates(2024)</th>
                      <th>Time</th>
                    </tr>
                   </thead>
                   <tbody> 
                     
                    <tr>
                     <td>Varalakshmi Vratam</td>
                     <td>Friday</td>
                     <td>Aug 16 </td>
                     <td>7:00 pm</td>
                    </tr> 
                    <tr>
                     <td>Sani Pradosham</td>
                     <td>Saturday</td>
                     <td>Aug 17 </td>
                     <td>3:00 pm</td>
                    </tr>
                    
                    <tr>
                     <td>Gokulashtami</td>
                     <td>Sunday</td>
                     <td>Aug 25 </td>
                     <td>5:30 pm</td>
                    </tr>                   
                   </tbody>
                  </Table>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card border="primary" className="regularEvents">
            <Card.Header style={{textAlign: "center", fontWeight: "bold"}}>Special Event</Card.Header>
            <Card.Body>
              <Card.Text>
                 <Table size="sm">
                   <thead>
                     <tr>
                      <th style={{textAlign: "center"}}>2024</th>
                      <th></th>
                    </tr>
                   </thead>
                   <tbody> 
                   <tr>
                     <td>Avani Avittam/ Yajur Upakarma</td>
                     <td>Monday</td>
                     <td> Aug 19</td>
                     <td>Batch I : 6 - 7.30 am </td>
                     </tr>
                     <tr>
                       <td>
                       </td>
                       <td>
                       </td>
                       <td>
                        
                       </td>
                     <td>Batch II: 8 - 10 a.m ( with homam)</td>
                    </tr> 
                    <tr>
                     <td>Gayathri Japam</td>
                     <td>Tuesday</td>
                     <td>Aug 20</td>
                     <td>6:00 - 8:00 am</td>
                    </tr> 
                    <tr>
                     <td> <a href = "/assets/avaniavittam2024.pdf"> Download Kamo Karisheeth Japam/Gayathri Japam</a></td>
                     <td></td>
                     <td></td>
                     <td></td>
                    </tr> 
                   
                   </tbody>
                  </Table>
              </Card.Text>
            </Card.Body>
          </Card>
          
        </CardGroup>
       
       <p class="round-corners-10px" style={{display: "inline-block",width:"100%",textAlign: "left",justifyContent: 'flex-end',fontSize: "0.9rem"}}>
       <Image roundedCircle src="/assets/rajarajeswariambal.jpg" style={{width:"30vh", height:"30vh",float:"left"}} ></Image>
        Raja Rajeswari is the presiding deity of Sri Chakra she is the mother of love and grace. All sentient and insentient being are linked to, and dependent upon her power for their existence. She is the personification of the Divine desire to create the universe and also the Divine desire to merge the creation back into the ultimate Bhraman (God the father). This two fold desire is the basis of love, the vivifying bond that ties the Creator and the created. She controls our minds and sensory organs and holds the key to transcendental knowledge. She resides on the top of the head (Sahasrara) , and also in the second chakra (Swadisthana). 
       
       She is having for hands and three eyes. She wields in her four hands the noose, the goad , sugar cane bow, and five flowered arrows. Her three eyes are Sun, Moon and fire representing the three triads in creation. Her crown is decorated with the crescent moon the symbol of divine delight. The noose represents strong determination and love by which She binds the whole creation. The goad is the symbol of the power of knowledge, it is the wrath of fiery emotions by which the whole creation is spurred into activity. The sugar cane stem depicts the mind principle of man. The five flowered arrows are the five tanmatras ,sound, touch, form, taste and smell the source of the five elements. She is having three forms, the gross physical form (sthula sharira), subtle astral form, (sukshma sharira) and the causal archetypal form of the macrocosm and microcosmic creation. 
       
       When She is meditated upon, this ultimate energy, in the first and second chakra (Muladhara and Swadhisthana) the sadhaka (spiritual aspirant) is performing internal fire sacrifice. She emerges out of the lotus chakras as Kundalini the supreme power elevating the sadhaka. 
       
       The graceful Mother removes evil and develops goodness within all of us.
       
       She is the power of Bhraman; pure consciousness embodied. She is the bliss of "Sat - Chit - Ananda" existence, knowledge and bliss. 
       <h4 style={{color: "red", textAlign: "center"}}>"Do good and be good" </h4>
      </p>

     
      </div>
    
     </div>
        );
    }

    aboutPriest(){
        return(
            <div>
                <h3>Pandit Krishnan Sastrigal</h3>
                <br />
                <img src="/assets/rrtpriest.jpg" style={{width:"40vh", height:"40vh",float:"left"}} alt="rrtpriest" ></img>
                <p style={{display: "inline-block",width:"46%",textAlign: "left"}}>

                  Pandit. Sri. Krishnan Sastrigal has over 20 years of experience as a priest at various temples in India, USA, Malaysia and Sri Lanka, and as a “Purohit” in both India and abroad. He has served in prominent temples in the US, namely the Ganesha Temple, Flushing (NY), Sri Lakshmi Temple, Ashland (MA) and the Hindu Temple of Wisconsin. His most recent position was as a priest was at the Maha Ganapathy temple of Arizona.  

                  Pandit. Krishnan Sastrigal learnt Krishna Yajurveda Adhyayana at Sri Sri Kanchi Kamakoti Peetam, Kumbakonam – Tamil Nadu, India – under the tutelage of Sri. Manjakudi Venkatrama Sastrigal and Sri. Krishnamoorthy Ganabadigal. He is fluent in Tamil, Telugu and English. 

                  Pandit. Krishnan Sastrigal is well versed in performing rituals like Kumbabhishekam, Laksharchana, Ganapathy Homam, Sudharshana Homam, Navagraha Homam and Mrityunjaya Homam at Hindu temples and residences. He is also available to perform rituals and ceremonies including Hindu weddings, baby shower (seemantham), Satya Narayana Puja, Upanayanam, Gruhapravesh, Sashtiyabdapoorthi (60th Birthday), Sadabishekam (80th Birthday), Bheemarathasanthi, Bhagavathi Seva, funeral services, srardha/hiranya.
                </p>
           </div>
        );

    }

    drivingDirections(){
        return (
            <div>
            <DrivingDirections
              width="962" 
              height="649" 
              frameborder="0" 
              scrolling="no" 
              marginheight="0" 
              marginwidth="0" 
              id="gmap_canvas" 
              src="https://maps.google.com/maps?width=962&amp;height=649&amp;hl=en&amp;q=2350%20Pragon%20Drive%20%20San%20Jose+(Sri%20Raja%20Rajeswari%20Temple%20,%20San%20Jose,%20CA%20)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">

              </DrivingDirections> 
              <a href='https://embed-map.org/'>embedding google map</a> 
              <script type='text/javascript' src='https://maps.app.goo.gl/kPhtVMuWmLnPCynV7'>

              </script>
            </div>
        
        );

    }

    religiousServices(){
        return(
          <div style={{textAlign: "left"}}>
             <h3 style={{textAlign: "center"}}>Religious Services</h3>
             <p> Our priests can perform religious and ancillary services, upon request at the temple or at your home. There are also multiple options for sponsorships at the temple. Please see below and email or call us for more information.</p>
             <p> In addition, please donate generously to run and maintain your temple. Donations can be made in cash or check at the temple. You can also donate food items (contact temple for items to donate) for Abhishekam and Annadana purposes.</p>
            <p style={{color: "red"}}>* Note: Puja items are usually available at any Indian grocery store. </p>
            <p style={{color: "red"}}> Temple Donation NOT includes Priest Dakshina</p>
            <p style={{color: "red"}}>Its our custom and tradition to give dakshina to priest after Pooja to make him satisfy please check with priest about Dakshina.</p>
            <Button onClick={() => this.setState({showPriestServiceRegistration: true})}>Priest Service Registration</Button>
           
            <Table striped bordered size="sm">

            <thead>
              <tr style={{textAlign: "center", backgroundColor: "orange"}}>
                <th>Services</th>
                <th>At Temple</th>
                <th>At Home</th>
                <th> List</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Vahana Puja</td>
                <td>$51</td>
                <td>$101</td>
              </tr>
              <tr>
                <td>Annaprasanam</td>
                <td>$51</td>
                <td>$101</td>
              </tr>
              <tr>
                <td>Hair Offering</td>
                <td>$51</td>
                <td>$101</td>
              </tr>
              <tr>
                <td>Akshara Abhyasam</td>
                <td>$51</td>
                <td>$101</td>
              </tr>
              <tr>
                <td>Upanayanam</td>
                <td>$151</td>
                <td>$351</td>
              </tr>
              <tr>
                <td>Wedding</td>
                <td>$251</td>
                <td>$551</td>
              </tr>
              <tr>
                <td>Bhoomi Puja</td>
                <td>NA</td>
                <td>$201</td>
              </tr>
              <tr>
                <td>Shashtiabthapoorthi</td>
                <td>$151</td>
                <td>$251</td>
              </tr>
              <tr>
                <td>Hiranya Shraadham</td>
                <td>$51</td>
                <td>$201</td>
                <Button variant="outline-info" onClick={() => {
                           this.setState({showPoojaItemList: true})
                           this.setState({poojaItemIndex: 2})
                          }
                      }>
                        Pooja Item List
                </Button>
              </tr>
              <tr>
                <td>Satyanarayana Pooja</td>
                <td>$51</td>
                <td>$201</td>
              </tr>
              <tr>
                <td>Grihapraveshaam</td>
                <td>NA</td>
                <td>$201</td>
              </tr>
              <tr>
                <td>Seemantham</td>
                <td>$151</td>
                <td>$201</td>
              </tr>
              <tr>
                <td>Ganapathy Homam</td>
                <td>$51</td>
                <td>$201</td>
                <Button variant="outline-info" onClick={() => {
                           this.setState({showPoojaItemList: true})
                           this.setState({poojaItemIndex: 1})
                          }
                      }>
                        Pooja Item List
                </Button>
              </tr>
              <tr>
                <td>Others</td>
                <td>Contact Priest</td>
                <td>Contact Priest</td>
              </tr>
            </tbody>
          </Table>
            
        </div>
        );

    }

    monthlyDonations(){
        return(
            <div style={{textAlign: "left"}}>
                 <h3 style={{textAlign: "center"}}> Monthly Seva Donations</h3>
             <Table striped  size="sm">
              <tbody>
               <tr>
                 <td>Flowers</td>
                 <td>$200</td>
                </tr>
               <tr>
                 <td>Deepm oil</td>
                 <td>$50</td>
               </tr>
               <tr>
                 <td>Milk</td>
                 <td>$75</td>
               </tr>
               <tr>
                 <td>Neivedhyam</td>
                 <td>$100</td>
               </tr>
               <tr>
                 <td>Dravyam</td>
                 <td>$100</td>
               </tr>
               <tr>
                 <td>Vastra</td>
                 <td>$75</td>
               </tr>
               <tr>
                 <td>Anna Dhanam</td>
                 <td>$100</td>
               </tr>
               <tr>
                 <td>Vedic Expenses</td>
                 <td>$250</td>
               </tr>
               <tr>
                 <td>Homam</td>
                 <td>$150</td>
               </tr>
               <tr>
                 <td>Prasadam-Plates</td>
                 <td>$50</td>
               </tr>
               <tr>
                 <td>Rent</td>
                 <td>$4000</td>
               </tr>
             </tbody>
           </Table>

            </div>

        )

    }

    sponsorships(){
        return(
        <div  style={{textAlign: "left"}}>
        <h3 style={{textAlign: "center"}}>Sponsorships</h3>
         <p>
         There are also multiple options for sponsorships at the temple. Please see below and email or call us for more information.
         In addition, please donate generously to run and maintain your temple. Donations can be made in cash or check at the temple. You can also donate food items (contact temple for items to donate) for Abhishekam and Annadana purposes.
         </p>
         <Table striped  size="sm">
          <thead >
          <tr>
            <th colspan="2" style={{textAlign: "center",backgroundColor: "orange"}}>Annaual Sponsorships</th>
           </tr>
          </thead>
          <tbody>
           <tr>
            <td>Sri Raja Rajeswari Vasthram</td>
            <td>$251</td>
           </tr>
           <tr>
            <td>Sarva Devatha Nithya Pooja & Weekly Abhishekam for Sri Rajarajeswari</td>
            <td>$3001</td>
           </tr>
           <tr>
            <td>Abhisekam for 1 year</td>
            <td>$1501</td>
           </tr>
           <tr>
            <td>Flowers for 1 year</td>
            <td>$2501</td>
           </tr>
           <tr>
            <td>Annadhanam for 1 year</td>
            <td>$1501</td>
           </tr>
           <tr>
            <td>Nithya Pooja and Ganapthi Abhishekam for a year</td>
            <td>$1501</td>
           </tr>
           <tr>
            <td>Nithya Pooja and Navagraha Abhisekam for a year</td>
            <td>$1501</td>
           </tr>
           <tr>
            <td>Nithya Pooja and Siva Abhishekam for a year</td>
            <td>$1501</td>
           </tr>
           <tr>
            <td>Nithya Pooja and Shiva Abjishekam for a year</td>
            <td>$1501</td>
           </tr>
           <tr>
            <td>Nithya Pooja and Balaji Abhishekam for a year</td>
            <td>$1501</td>
           </tr>
         </tbody>
          </Table>
          <br></br>
          <Table striped  size="sm">
          <thead >
          <tr>
            <th colspan="2" style={{textAlign: "center",backgroundColor: "orange"}}>Sponsorships</th>
           </tr>
          </thead>
          <tbody>
           <tr>
            <td>Abhishekam</td>
            <td>$75</td>
           </tr>
           <tr>
            <td>Ganapathi Homam</td>
            <td>$151</td>
           </tr>
           <tr>
            <td>Navagraha Homam</td>
            <td>$151</td>
           </tr>
           <tr>
            <td>Sathyanarayana Puja (Group on Poornima Day)</td>
            <td>$51</td>
           </tr>
           <tr>
            <td>Vahana Puja</td>
            <td>$51</td>
           </tr>
           <tr>
            <td>One day Annadanam</td>
            <td>$101</td>
           </tr>
           
           

           </tbody>
           </Table>
        </div>

        );
    }

    photoGallery(){
      return(
        <div  style={{textAlign: "left"}}>
        <h3 style={{textAlign: "center"}}>Photo Gallery</h3>
          
        <Tabs defaultActiveKey="kumbhabhishekam" id="photogallery">
          <Tab eventKey="kumbhabhishekam" title="Kumbhabhishekam">
          <ImageGallery photos={KumbhabhishekamPhotos} />
          </Tab>
          <Tab eventKey="maharudram" title="Maha Rudram">
            <ImageGallery photos={MahaRudramPhotos} /> 
          </Tab>
          <Tab eventKey="others" title="Others">
          <Nav className="flex-column">
             <Nav.Item>
                <Nav.Link href="https://www.facebook.com/media/set/?set=a.10204525944664465.1073742031.1708530192&type=1&l=cd4c8c5ad7">Maha Rudram 2018( photos uploaded to facebook)</Nav.Link>
             </Nav.Item>
             <Nav.Item>
                <Nav.Link href="https://www.youtube.com/embed/795Aks6fSEI?ecver=1">Maha Rudram 2017(You tube video)</Nav.Link>
             </Nav.Item>
             <Nav.Item>
                <Nav.Link href="https://preamble.smugmug.com/PublicEvents/SRRT-Laksharachana-Event-2021/n-zFTmDm/">Ambal Pradhistadhina Laksharchana - 2021</Nav.Link>
             </Nav.Item>
           </Nav>  
          </Tab>
       </Tabs>
      </div>
      );
    }

    homeTab() {
        return(
        <Tab.Container id="left-tabs-example" defaultActiveKey="first" style={{textAlign: "left"}}>
             <Row>
               <Col sm={2}>
               <Nav variant="pills" className="flex-column">
               <Nav.Item>
                  <Nav.Link eventKey="first" style={{textAlign: "left"}}>Welcome</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="second" style={{textAlign: "left"}}>About our Priest</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="third" style={{textAlign: "left"}}>Upcoming Events</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="four" style={{textAlign: "left"}}>PriestServiceRegistration</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="five" style={{textAlign: "left"}}>Directions</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="six" style={{textAlign: "left"}}>Religious Services</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="seven" style={{textAlign: "left"}}>Monthly Seva Donations</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="eight" style={{textAlign: "left"}}>Sponsorships</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="nine" style={{textAlign: "left"}}>Panchang</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="ten" style={{textAlign: "left"}}>Photo Gallery</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                 <Nav.Link eventKey="eleven" style={{textAlign: "left"}}>Announcements</Nav.Link>
               </Nav.Item>
               
              </Nav>
              </Col>
              <Col sm={9}>
               <Tab.Content>
                 <Tab.Pane eventKey="first">
                   {this.welcomeTab()}
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  {this.aboutPriest()}
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                   <MonthlyEventCalendar />
                </Tab.Pane>
                <Tab.Pane eventKey="four">
                  <PriestServiceRegistration />
                </Tab.Pane>
               
                <Tab.Pane eventKey="five">
                  {this.drivingDirections()}
                </Tab.Pane>
                <Tab.Pane eventKey="six">
                  {this.religiousServices()}
                </Tab.Pane>
                <Tab.Pane eventKey="seven">
                  {this.monthlyDonations()}
                </Tab.Pane>
                <Tab.Pane eventKey="eight">
                  {this.sponsorships()}
                </Tab.Pane>
                <Tab.Pane eventKey="nine">
                  <Panchang />
                </Tab.Pane>
                <Tab.Pane eventKey="ten">
                  {this.photoGallery()}
                </Tab.Pane>
                <Tab.Pane eventKey="eleven">
                <img src="/assets/flyers/SRRT_flyer_covid-19.jpg" style={{width:"1275px", height:"1650px"}} alt="covid flyer"></img>
                </Tab.Pane>
                </Tab.Content>
              </Col>
             </Row>
         </Tab.Container>
        );
    }
    
  
    homeTabHorizontal() {
      return(
        <div  style={{textAlign: "left"}}>
        <Tabs id="maintab" defaultActiveKey="first">
        <Tab eventKey="first" title="Welcome">
          {this.welcomeTab()}
       </Tab>
       <Tab eventKey="three" title="Religious Services">
         {this.religiousServices()}
       </Tab>
     
       <Tab eventKey="five" title="Monthly Donations">
         {this.monthlyDonations()}
       </Tab>
       <Tab eventKey="six" title="Sponsorships">
         {this.sponsorships()}
       </Tab>
      
      
       <Tab eventKey="seven" title="How to Donate">
         {this.DonateTab()}
       </Tab>
      
       <Tab eventKey="nine" title="Panchang">
         <Panchang />
       </Tab>
       <Tab eventKey="ten" title="Photo Gallery">
         {this.photoGallery()}
       </Tab>
       <Tab eventKey="eleven" title="About Priest">
         {this.aboutPriest()}
       </Tab>
       </Tabs>
       </div>
      );
    }

    render()
    { 
        return(
            <div>
                {this.homeTabHorizontal()}  
                {this.state.showPriestServiceRegistration ? this.handlePriestServiceRegistration() : null}   
                {this.state.showPoojaItemList ? this.handlePoojaItemList() : null}               
            </div>
        );
    }
    
/*
 render()
    { 
        return(
          <Tabs activeKey={this.state.activeTab} id="uncontrolled-tab-example" onSelect={this.handleSelect}>
            <Tab eventKey="welcome" title={<span style={{height:"5vh",width:"15vh"}}>Home</span>}>
                {this.homeTab()}
            </Tab>
          </Tabs>
            <Button onClick={() => this.setState({showPriestServiceRegistration: true})}>Priest Service Registration</Button>
        )
  }
  */
}